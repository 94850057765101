import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Having Consistent School Events`}</h1>
    <p>{`Throughout this whole year, I felt a lot of guilt because I was not planning enough events for the Junior Class. I felt that both my council and I really wanted to do in-person events, but it was just not possible. So that's when, on a bright March morning, I had told my council we need to have more consistent events. They all agreed. We quickly brainstormed 7 weeks of programming, starting from a movie night and ending with a Valorant tournament in April. These are two very different activities because we wanted it to cater to different people. Our goal is for different people to come to each event... make our target audience different groups on campus. Artists, Gamers, Academics, and more— we created an event for each week that we think everyone would want to join. `}</p>
    <p>{`Even if 5 people come to the event, it means that there are 5 IHS students who are enjoying themselves at a school sponsored event. Hopefully those 5 people invite 5 more the next, but if they don't, then that's OK! A lot of the times, we focus on numbers and see if it is worth doing activities if it's low participation. We should be looking back to promote the event harder, instead of cancelling it.`}</p>
    <p>{`Next year, I want every ASB member to have their own passion projects. These passion projects can be any kind of event or project you want to run. For me, last year, it was a TED talk event. For some people, it might be a carnival! Everyone in ASB should plan an event they're interested in. This will make sure that everyone is passionate about their event, while also creating a consistent event schedule for next year!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      